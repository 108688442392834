import { RendererObject, marked } from 'marked'
import DOMPurify from 'dompurify'

const renderer: RendererObject = {
  list(body, ordered, start) {
    return `
      <ul class="list-disc pl-8">
        ${body}
      </ul>
    `
  },
  listitem(text, task, checked) {
    return `
      <li class="mb-2">${text}</li>
    `
  },
}

marked.use({ renderer })

export const markdownParse = (rawContent: string) => {
  const cleanedContent = rawContent.replace(/^[\u200B\u200C\u200D\u200E\u200F\uFEFF]/, '')
  return DOMPurify.sanitize(marked.parse(cleanedContent), { USE_PROFILES: { html: true } })
}
