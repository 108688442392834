export interface IModal {
  type: string
  props: any
}

export interface Media {
  attachment: {
    id: number;
    media_type: MediaType | String,
    original_filename?:String,
    url: string
  },
  caption: string,
}

export enum MediaType {
  image = 'IMAGE',
  video = 'VIDEO',
}

export interface ILocale {
  code: string
  name: string
}

export interface IToastProps {
  invoiceNumber: number
  isHiddenDownload: boolean
}
