<template>
  <span v-html="formarttedContent" />
</template>

<script setup lang="ts">
import { OnboardingParamsProviderKey } from '~/services/constants'
import { boldContent, interpolateVariables } from '~/services/helpers'
import { markdownParse } from '~/services/markdown.helper'

const props = defineProps<{
  content: string,
  bold?: boolean,
  interpolateParams?: boolean,
  markdown?: boolean,
}>()

const onboardingParams = inject(OnboardingParamsProviderKey)!

const { content } = toRefs(props)
const formarttedContent = computed(() => {
  let formatted = content.value || ''

  if (props.bold) {
    formatted = boldContent(formatted)
  }
  if (props.interpolateParams) {
    formatted = interpolateVariables(formatted, onboardingParams?.value)
  }
  if (props.markdown) {
    formatted = markdownParse(formatted)
  }

  return formatted
})
</script>
